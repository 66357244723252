export const BREAKPOINTS = {
  xs: 375,
  sm: 550,
  md: 720,
  lg: 1024,
  xl: 1445,
};

// Helper functions
export const isMobile = () => window.innerWidth < BREAKPOINTS.md;
export const isDesktop = () => window.innerWidth >= BREAKPOINTS.md;